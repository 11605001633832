<template>
    <div class="box">
        <Header type="expert" :logo="logoUrl" :navList="navList" :typeList="[]" :navCurrentId="navCurrentId">
            <div slot="search" style="display: none"></div>
        </Header>

        <div :class="['content',dataList.length ? '' : 'flex y-center x-center']">
            <template v-if="dataList.length">
                <div class="person-item flex x-left" v-for="(item,index) in dataList" :key="index">
                    <div class="user-info flex-column x-left y-center" @click="handleToExpertDta(item.user_id)">
                        <div class="avatar">
                            <img :src="item.avatar | urlFilter(750)" alt="">
                        </div>
                        <div class="user-dta flex-item">
                            <div class="name">{{ item.real_name }}</div>
                            <div class="company">
                                {{ item.org_cnname }}
                            </div>
                        </div>
                    </div>
                    <!--  简历  -->
                    <div class="resume flex-item t-l">
                        <a-tabs @change="callback">
                            <a-tab-pane key="1" :tab="index + '任职'" v-for="(item,index) in item.data" :key="index">
                                <div class="resume-items">
                                    <div class="resume-item flex x-left" v-for="(data,idx) in item" @click="handleToNextPage(data)">
                                        <div class="time">
                                            <span v-if="data.BeginTime">{{ data.BeginTime | formatDate('yyyy年') }} -</span>
                                            <span v-if="data.EndTime">{{ data.EndTime | formatDate('yyyy年') }}</span>
                                        </div>
                                        <div class="surname">
                                            {{index}}{{data.b_name}}第{{data.Sequence}}届{{data.t_name}}
                                        </div>
                                        <div class="position">
                                            {{ data.position }}
                                        </div>
                                    </div>
                                </div>
                            </a-tab-pane>
                        </a-tabs>
                    </div>
                </div>
            </template>
            <a-empty class="mt30 mb20" v-else />
        </div>

        <a-pagination
            v-model="current"
            :page-size-options="pageSizeOptions"
            :total="total"
            show-size-changer
            :page-size="pageSize"
            @showSizeChange="onShowSizeChange"
            @change="getListData"
        >
            <template slot="buildOptionText" slot-scope="props">
                <span v-if="props.value !== '50'">{{ props.value }}条/页</span>
                <span v-if="props.value === '50'">全部</span>
            </template>
        </a-pagination>


        <Footer></Footer>
    </div>
</template>

<script>
import {exportLogo , expertNavList } from "@/config/const";
import Header from '@/components/layout/layout-header';
import Footer from "@/components/layout/layout-footer.vue";
export default {
    name: "expertHome",
    components : {
        Header , Footer
    },
    data(){
        return {
            logoUrl: exportLogo,
            navList: expertNavList,
            navCurrentId: 5,//当前页面菜单对应下标
            expertName:'',// 搜索专家名称
            dataList : [],// 列表数据
            // 分页
            pageSizeOptions: ['10', '20', '30', '40', '50'],
            current: 1,
            pageSize: 10,
            total: 50,
        }
    },
    created() {
      this.expertName = this.$route.query.expertName;
      this.getListData();
    },
    mounted() {
        let headerHeight = document.querySelector('.header').offsetHeight;
        let footerHeight = document.querySelector('.footer').offsetHeight;
        let countHeight = parseInt(footerHeight) + parseInt(headerHeight) + 56;
        document.querySelector('.content').style.minHeight = `calc(100vh - ${countHeight}px)`;
    },
    methods : {
        callback(key) {
            console.log(key);
        },
        onShowSizeChange(current, pageSize) {
            this.pageSize = pageSize;
        },
        getListData(){
            this.request.post('ExpertSearchPc',{ name : this.expertName , limit : this.pageSize , page : this.current }).then(res=>{
                this.dataList = res.data.list || [];
                this.total = parseInt(res.data.count || 0);
            })
        },
        handleToExpertDta(uid){
            this.$router.push({ path: '/person-detail', query: { uid } });
        },
        handleToNextPage(data){
            let { CouncilID , BranchId , PeriodID } = data;
            this.$router.push({
                path : '/job-warehouse/rollCall',
                query : {
                    CouncilID,
                    BranchId,
                    PeriodID
                }
            })
        }
    }
}
</script>

<style scoped lang="less">
    .content{
        width: 1240px;
        margin: 0 auto;
        .person-item{
            width: 1200px;
            height: 220px;
            background: #FFFFFF;
            box-shadow: 0px 1px 6px 0px rgba(0,0,0,0.1);
            border-radius: 10px;
            overflow: hidden;
            margin: 0 auto 30px;
            .user-info{
                cursor: pointer;
                flex-shrink: 0;
                height: 100%;
                width: 153px;
                .avatar{
                    width: 100%;
                    height: 140px;
                    background: deepskyblue;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: top;
                    }
                }
                .user-dta{
                    width: 100%;
                    background: #fafbff;
                    padding: 10px 20px;
                    box-sizing: border-box;
                    text-align: center;
                    .name{
                        color: #333333;
                        font-size: 16px;
                    }
                    .company{
                        color: #999999;
                        font-size: 14px;
                    }
                }
            }
            .resume{
                //width: calc(100% - 153px);
                .resume-items{
                    height: 158px;
                    overflow-y: auto;
                    .resume-item{
                        padding-left: 30px;
                        margin-bottom: 18px;
                        cursor: pointer;
                        & > div{
                            margin-right: 50px;
                        }
                        .time{
                            min-width: 140px;
                        }
                        .surname{
                            min-width: 140px;
                        }
                        .position{
                            min-width: 120px;
                        }
                    }
                }
            }
        }
    }

    /deep/ .ant-tabs-nav .ant-tabs-tab:first-child{
        margin-left: 30px;
    }
</style>